<template>
  <div class="modal-content">
    <div class="box">
      <div class="columns is-mobile">
        <div class="column">
          <p class="subtitle has-text-primary">Contact Us</p>
        </div>
        <div class="column is-narrow">
          <a class="has-text-danger" @click="$modal.close">Close</a>
        </div>
      </div>
      <fullDetails />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContactFooter',
  components: {
    fullDetails: () => import('../components/experian/ContactFullDetails')
  }
}
</script>
